import {
  Analytics,
  AnalyticsEvent,
  AnalyticsEventProperties,
  AnalyticsEvents,
  AnalyticsOptions,
  createAnalyticsEvent,
} from '../types';

const defaultUrl = process.env.PRIMER_ANALYTICS_API_URL as string;
const maxStringLength = 1024 * 5;
const sessionStorageKey = (url: string) => `@primer/analytics/${url}`;

export function createHttpAnalyticsProvider({
  url = defaultUrl,
  ...options
}: AnalyticsOptions & { url?: string } = {}): Analytics {
  // Server Side Rendering check
  if (typeof document !== 'undefined')
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') sendEvents(url);
    });

  const trackEvent =
    (eventType: AnalyticsEvents) =>
    async (properties: AnalyticsEventProperties) => {
      // Server Side Rendering check
      if (typeof navigator === 'undefined') return;

      const event = await createAnalyticsEvent<AnalyticsEventProperties>(
        eventType,
        properties,
        options,
      );

      const mapValue = <T>(value: T) => trimIfString(stringifyFunction(value));
      const trimmedEvent = traverseObjectWithFunction(
        event,
        mapValue,
      ) as AnalyticsEvent<AnalyticsEventProperties>;

      queueEvent(url, trimmedEvent);
    };

  return {
    crashEvent: trackEvent(AnalyticsEvents.CRASH_EVENT),
    messageEvent: trackEvent(AnalyticsEvents.MESSAGE_EVENT),
    networkCallEvent: trackEvent(AnalyticsEvents.NETWORK_CALL_EVENT),
    sdkFunctionEvent: trackEvent(AnalyticsEvents.SDK_FUNCTION_EVENT),
    timerEvent: trackEvent(AnalyticsEvents.TIMER_EVENT),
    v1Event: trackEvent(AnalyticsEvents.V1_EVENT),
  };
}

function sendEvents(url: string) {
  const key = sessionStorageKey(url);
  const events = sessionStorage.getItem(key);
  sessionStorage.removeItem(key);
  if (events) navigator.sendBeacon(url, events);
}

function queueEvent(
  url: string,
  event: AnalyticsEvent<AnalyticsEventProperties>,
) {
  const key = sessionStorageKey(url);
  sessionStorage.setItem(
    key,
    `[${[sessionStorage.getItem(key)?.slice(1, -1), JSON.stringify(event)]
      .filter(Boolean)
      .join(',')}]`,
  );
}

function traverseObjectWithFunction(
  obj: AnalyticsEvent<AnalyticsEventProperties>,
  fn: <T>(value: T) => T | string,
) {
  const processValue = (value: typeof obj) =>
    typeof value === 'object' && value !== null
      ? traverseObjectWithFunction(value, fn)
      : fn(value);

  if (Array.isArray(obj)) return obj.map(processValue);

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, processValue(value)]),
  );
}

// Trim strings properties to a threshold to prevent slowdowns due to sending big
// analytics messages. The threshold is arbitrary, tune as needed.
function trimIfString<T>(value: T) {
  if (typeof value === 'string' && value.length > maxStringLength)
    return `${value.slice(0, maxStringLength)}...`;

  return value;
}

// Replace functions by their function names if possible
function stringifyFunction<T>(value: T) {
  if (typeof value === 'function') return value.name || 'function';

  return value;
}
